// 费用列表
<template>

    <div class="costList baseBg">

        <div class="listBox mainWhiteBg">
            <el-tabs v-model="searchForm.queryFlag"
                     ref="searchTab"
                     class="searchTab"
                     @tab-click="getList()">
                <el-tab-pane name="1"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_报销_费用列表_待报销费用')">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,'Tab_报销_费用列表_待报销费用')"
                         slot="label">
                        <el-badge :type="activeName == '1' ? 'primary' : 'warning'"
                                  :value="counts['1']"
                                  class="item">
                            待报销费用
                        </el-badge>
                    </div>
                </el-tab-pane>
                <el-tab-pane name="2"
                             v-if="commonJs.isShowBtn(detailRole, 'Tab_报销_费用列表_历史费用')">
                    <div class="tabsLabel count"
                         @click="commonJs.setTabId(detailRole,'Tab_报销_费用列表_历史费用')"
                         slot="label">
                        历史费用
                    </div>
                </el-tab-pane>
            </el-tabs>
            <div class="tableBox">
                <div class="greySearchBar">
                    <el-form :model="searchForm"
                             ref="searchForm"
                             :rules="searchFormRules"
                             :inline="true">

                        <el-form-item label="费用类型"
                                      prop="categoryId">
                            <el-cascader v-model="searchForm.categoryId"
                                         placeholder="请选择费用类型"
                                         :show-all-levels="false"
                                         :options="categoryTypeOpts"
                                         :props="{ emitPath: false,
                                            value: 'id' }"
                                         clearable></el-cascader>
                        </el-form-item>

                        <el-form-item label="关联项目/机会"
                                      prop="projectId">
                            <ComSelectProjectOrReserveProject v-model="searchForm.projectId"></ComSelectProjectOrReserveProject>
                        </el-form-item>

                        <el-form-item label="报销人"
                                      prop="occurUserId">
                            <select-all-company-person v-model="searchForm.occurUserId"
                                                       placeholder="请选择报销人"
                                                       clearable></select-all-company-person>
                        </el-form-item>

                        <el-form-item label="日期"
                                      prop="dates">
                            <el-date-picker v-model="searchForm.dates"
                                            type="daterange"
                                            value-format="yyyy-MM-dd"
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期">
                            </el-date-picker>
                        </el-form-item>

                        <el-button type="primary"
                                   @click="getList">搜索</el-button>
                        <el-button @click="resetSearchForm">清除</el-button>
                    </el-form>
                </div>
                <div class="tableBox">
                    <div class="greySearchBar control">
                        <el-button type="success"
                                   class="flr"
                                   @click="openpopEditCost()">
                            新增费用
                        </el-button>
                        <com-export-table-to-excel class="flr"
                                                   :searchForm="searchForm"></com-export-table-to-excel>
                    </div>

                    <el-table :data="tableData.data"
                              border
                              :height="tableHeight"
                              tooltip-effect="dark"
                              stripe>
                        <el-table-column label="序号"
                                         type="index"
                                         align="center"
                                         width="60">
                        </el-table-column>
                        <el-table-column prop="occurDate"
                                         label="发生日期"
                                         align="center"
                                         width="128">
                        </el-table-column>
                        <el-table-column prop="type"
                                         label="费用类型"
                                         align="left"
                                         min-width="auto"
                                         width="160">
                        </el-table-column>
                        <el-table-column prop="occurUserName"
                                         label="报销人"
                                         align="left"
                                         min-width="auto"
                                         width="95">
                        </el-table-column>
                        <el-table-column prop="deptName"
                                         label="部门"
                                         align="left"
                                         min-width="auto"
                                         width="100">
                        </el-table-column>
                        <el-table-column prop="projectName"
                                         label="项目/机会"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="amount"
                                         label="金额（元）"
                                         align="left"
                                         min-width="auto"
                                         width="auto">
                        </el-table-column>
                        <el-table-column prop="remark"
                                         label="费用说明"
                                         align="left"
                                         min-width="auto"
                                         width="220">
                            <template slot-scope="scope">
                                <el-tooltip class="item"
                                            effect="dark"
                                            :content="scope.row.remark">
                                    <p class="ellipsis"
                                       v-if="scope.row.remark">{{scope.row.remark}}</p>
                                    <span v-else>暂无</span>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column prop="id"
                                         label="操作"
                                         align="left"
                                         fixed="right"
                                         width="160">
                            <template slot-scope="scope">
                                <el-button type="primary"
                                           v-if="searchForm.queryFlag === '2'"
                                           size="mini"
                                           @click="openpopEditCost(scope.row, true)">查看</el-button>
                                <template v-else>
                                    <el-button type="primary"
                                               size="mini"
                                               @click="openpopEditCost(scope.row)">编辑</el-button>
                                    <el-popconfirm confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   icon-color="red"
                                                   @confirm="del(scope.row.id)"
                                                   title="是否删除费用列表？">
                                        <el-button type="danger"
                                                   slot="reference"
                                                   size="mini">删除</el-button>
                                    </el-popconfirm>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>

                    <div class="pageFooter">
                        <!-- 金额统计 -->
                        <amount-statistical type="费用列表"
                                            :totalData="statistical"></amount-statistical>
                        <!-- 分页 -->
                        <Pagination :page="searchForm.page"
                                    :limit="searchForm.size"
                                    :total="pageTotal"
                                    @pagination="paginationChange" />
                    </div>
                </div>
            </div>
        </div>
        <!-- 编辑费用 -->
        <pop-edit-cost v-if="popEditCostVisible"
                       :id="detailsInfo && detailsInfo.id"
                       :justSee="justSee"
                       @update="getList()"
                       @close="closepopEditCost"></pop-edit-cost>

    </div>

</template>

<script>
import ComSelectProjectOrReserveProject from "../../modleComponents/project/comSelectProjectOrReserveProject";
import AmountStatistical from "../../components/amountStatistical/amountStatistical";
import ComExportTableToExcel from "../../components/export/comExportTableToExcel";
import PopEditCost from "./components/popEditCost";
import SelectAllCompanyPerson from "../../modleComponents/person/selectAllCompanyPerson";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
import commonAPI from "@/api/commonAPI.js";
import Pagination from "@/components/Pagination"; // 分页
export default {
    name: "costList",

    props: {},

    components: {
        ComSelectProjectOrReserveProject,
        AmountStatistical,
        ComExportTableToExcel,
        PopEditCost,
        SelectAllCompanyPerson,
        Pagination,
    },

    data() {
        return {
            searchForm: {
                queryFlag: "1", // tabs 1=待报销费用,2=历史费用
                dates: [], // 日期
                page: 1,
                size: 20,
            },
            searchFormRules: {},
            pageTotal: 0,

            tableData: {
                data: [],
            },
            // tabs对应文字
            tabsName: ["待报销费用", "历史费用"],
            counts: {
                1: 0, // 待报销费用 数量
                2: 0, // 历史费用 数量
            },

            categoryTypeOpts: [], // 费用类别
            cleanProvider: false, //清空客户供应商
            popEditCostVisible: false, // 编辑费用弹窗显示
            detailsInfo: null, // 当前编辑行
            justSee: false, // 仅查看费用
            detailRole: {}, //权限数据
            statistical: null, // 统计信息
        };
    },

    created() {},

    mounted() {
        this.commonJs.getFistTabId(this.detailRole, [
            "Tab_报销_费用列表_待报销费用",
            "Tab_报销_费用列表_历史费用",
        ]);
        // 选中第一个tab
        this.searchForm.queryFlag = this.$refs.searchTab.$children[1].name;
        // 列表数量
        this.getCostListCount();
        // 获取列表
        this.getList();
        // 获取费用类别下拉
        this.getCategoryTypeOpts();
    },

    methods: {
        // 列表数量
        getCostListCount() {
            let loading = Loading.service({
                target: document.querySelector(".el-tabs"),
            });
            API.getCostListCount()
                .then((res) => {
                    this.counts = {
                        1: res.content, // 待报销费用 数量
                    };
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            this.searchForm.status = [false, true][
                this.searchForm.queryFlag - 1
            ];
            let data = Object.assign({}, this.searchForm);
            API.getCostList(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                    this.statistical = res.content.statistical;
                    this.counts[this.searchForm.queryFlag] = res.content.total;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取费用类别下拉
        getCategoryTypeOpts() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            commonAPI
                .getCategoryTypeOpts("REIMBURSEMENT")
                .then((res) => {
                    loading.close();
                    this.categoryTypeOpts = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除费用列表
        del(id) {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.delCostList(id)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.getList();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 分页改变
        paginationChange(e) {
            this.searchForm.size = e.size;
            this.searchForm.page = e.page;
            this.getList();
        },
        // 客户的选择
        customerClick(node) {
            this.searchForm.companyId = node.id;
        },
        // 客户的清空
        clearProvider() {
            this.searchForm.companyId = "";
        },
        // 清空表单
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    this.searchForm.projectId = ""; // 清空关联项目
                    // 客户的清空
                    this.clearProvider();
                    this.cleanProvider = Math.random();
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 编辑费用 打开弹框
        openpopEditCost(row, justSee) {
            this.detailsInfo = row || null;
            this.justSee = justSee || false;
            this.popEditCostVisible = true;
        },
        // 编辑费用 关闭弹框
        closepopEditCost() {
            this.detailsInfo = null;
            this.justSee = false;
            this.popEditCostVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 360 });
        },

        //权限数据
        roleInfo() {
            return this.$store.state.role.roleInfo;
        },
    },

    watch: {
        roleInfo: {
            deep: true,
            immediate: true,
            handler: function (val) {
                this.detailRole = this.commonJs.tabIsAlive(
                    val,
                    "费用列表_列表"
                );
            },
        },
    },
};
</script>

<style lang="scss" scoped>
// .costList{

// }
</style>
