// 编辑费用
// popEditCost
<template>

    <div class="popEditCost">
        <el-dialog :title="dialogTitle"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   custom-class="popEditCostDialog"
                   :append-to-body="true"
                   width="1000px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-position="top"
                     :inline="false">

                <el-row :gutter="60">
                    <el-col :span="8">
                        <el-form-item label="费用类型"
                                      prop="categoryId">
                            <el-cascader v-model="form.categoryId"
                                         placeholder="请选择费用类型"
                                         :show-all-levels="false"
                                         :options="categoryTypeOpts"
                                         :props="{ emitPath: false,
                                            value: 'id' }"
                                         clearable></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="发生日期"
                                      prop="occurDate">
                            <el-date-picker v-model="form.occurDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            format="yyyy年MM月dd日"
                                            placeholder="请选择发生日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="报销人"
                                      prop="occurUserId">
                            <select-all-company-person v-model="form.occurUserId"
                                                       placeholder="请选择报销人">
                            </select-all-company-person>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="部门"
                                      prop="deptId">
                            <dept-select-tree ref="selectDepTree"
                                              placeholderStr="请请选择部门"
                                              :needClean="needClean"
                                              :shouldClean="true"
                                              :checkedInfo="{
                                                            label: form.deptName,
                                                            value: form.deptId
                                                      }"
                                              @handClear="clearDept"
                                              @treeSelectNodeClick="departmentClick"></dept-select-tree>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关联项目/机会"
                                      prop="projectId">
                            <ComSelectProjectOrReserveProject v-model="form.projectId"
                                                              stage="finish"
                                                              :outOpt="['成功']"
                                                              :defaultLabel="form.projectName"></ComSelectProjectOrReserveProject>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="金额（含税）"
                                      prop="amount">
                            <el-input v-model="form.amount"
                                      placeholder="请输入金额"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="税率（%）"
                                      prop="taxRate">
                            <el-input-number v-model="form.taxRate"
                                             placeholder="请输入税率"
                                             :min="0"
                                             :max="100"
                                             :controls="false"></el-input-number>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="费用说明"
                                      prop="remark">
                            <el-input v-model="form.remark"
                                      type="textarea"
                                      rows="5"
                                      placeholder="请输入费用说明"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="popItemBox"
                     v-if="form.id">
                    <div class="title">附件</div>
                    <div class="p20">
                        <com-file-upload-list :showUpBtn="justSee ? false : true"
                                              :uploadData="{resourceId: form.id, resourceType: 'expensesFile'}"
                                              :neddLoadFile="true"
                                              :justShowFistOne="false"
                                              :isNeedPopTemDownload="false"
                                              :showDownloadBtn="true"
                                              :showDelBtn="true"></com-file-upload-list>
                    </div>
                </div>

                <div class="footer"
                     v-if="!justSee">
                    <el-button type="danger"
                               @click="close()">取消</el-button>
                    <el-button type="primary"
                               @click="save()">确定</el-button>
                </div>
            </el-form>

        </el-dialog>
        <!-- 新增进项发票 -->
        <pop-cose-edit-input-bill v-if="popCoseEditInputBillVisible"
                                  @saveBill="saveBill"
                                  :details="detailsRow"
                                  :companyInput="true"
                                  @close="closepopCoseEditInputBill"></pop-cose-edit-input-bill>
    </div>
</template>

<script>
import ComSelectProjectOrReserveProject from "../../../modleComponents/project/comSelectProjectOrReserveProject";
import PopCoseEditInputBill from "./popCoseEditInputBill";
import DeptSelectTree from "../../../components/selectTree/deptSelectTree";
import SelectAllCompanyPerson from "../../../modleComponents/person/selectAllCompanyPerson";
import ComFileUploadList from "../../../components/upload/comFileUploadList";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
import commonAPI from "@/api/commonAPI.js";
import selectOpts from "@/api/selectOpts.js";
import { mapGetters } from "vuex";
export default {
    name: "popEditCost",

    props: {
        // 查询用id
        id: {
            type: String,
        },
        // 仅查看
        justSee: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        ComSelectProjectOrReserveProject,
        PopCoseEditInputBill,
        DeptSelectTree,
        SelectAllCompanyPerson,
        ComFileUploadList,
    },

    data() {
        return {
            dialogVisible: true,

            form: {
                id: null,
                categoryId: "", // 费用类型
                occurDate: "", // 发生日期
                occurUserId: "", // 报销人
                deptId: "", // 部门
                projectId: "", // 项目
                amount: "", // 金额（含税）
                taxRate: "", // 税率
                remark: "", // 费用说明
                inputInvoices: [],
            },
            formRules: {
                categoryId: {
                    required: true,
                    message: "请验证费用类型",
                    trigger: "blur",
                }, // 费用类型
                occurDate: {
                    required: true,
                    message: "请验证发生日期",
                    trigger: "blur",
                }, // 发生日期
                occurUserId: {
                    required: true,
                    message: "请验证报销人",
                    trigger: "blur",
                }, // 报销人
                deptId: {
                    required: true,
                    message: "请验证部门",
                    trigger: "blur",
                }, // 部门
                projectId: {
                    required: true,
                    message: "请验证项目",
                    trigger: "blur",
                }, // 项目
                amount: {
                    required: true,
                    message: "请验证金额（含税）",
                    trigger: "blur",
                }, // 金额（含税）
            },
            categoryTypeOpts: [], // 费用类别
            projectOpt: [], //项目列表下拉
            detailsInfo: {}, // 详情信息
            popCoseEditInputBillVisible: false, // 新增进项发票弹窗显示
            detailsRow: null, // 编辑进项发票
        };
    },

    created() {},

    mounted() {
        if (this.id) {
            this.form.id = this.id;
            // 获取详情
            this.getCostDetails();
        } else {
            // 获取新增的Id
            this.getId();
            setTimeout(() => {
                // 发生日期默认为当天
                this.form.occurDate = this.$moment().format("yyyy-MM-DD");
                // 报销人默认为本人
                this.form.occurUserId = this.userInfo.id;
                // 部门默认为本人所属部门
                this.form.deptId = this.userInfo.deptId;
            }, 400);
        }
        // 获取费用类别下拉
        this.getCategoryTypeOpts();
        // 获取项目下拉列表
        this.getProjectOpts();
    },

    methods: {
        // 获取详情
        getCostDetails() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getCostDetails(this.id)
                .then((res) => {
                    loading.close();
                    this.form = res.content;
                    let inputInvoices = res.content.invoiceViews.map((item) => {
                        return {
                            ...item,
                            type: item.type.name,
                            typeName: item.type.text,
                        };
                    });
                    this.form.inputInvoices = inputInvoices;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 保存费用
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".popEditCostDialog"),
                    });
                    let data = Object.assign({}, this.form);
                    API.saveCost(data)
                        .then(() => {
                            loading.close();
                            API.getCostDetails(this.form.id).then((res) => {
                                loading.close();
                                this.form = res.content;
                                this.$emit("update");
                                let data = Object.assign({}, res.content, {
                                    typeName: this.form.type,
                                    occurDate: res.content.occurDateStr,
                                });
                                this.$emit("addRow", data);
                                this.$message({
                                    message: "操作成功！",
                                    type: "success",
                                });
                                this.close();
                            });
                        })
                        .catch(() => {
                            loading.close();
                        });
                }
            });
        },
        // 获取新增项目的Id
        getId() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            commonAPI
                .getId()
                .then((res) => {
                    loading.close();
                    this.form.id = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取费用类别下拉
        getCategoryTypeOpts() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            commonAPI
                .getCategoryTypeOpts("REIMBURSEMENT")
                .then((res) => {
                    loading.close();
                    this.categoryTypeOpts = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取项目下拉列表
        getProjectOpts() {
            let loading = Loading.service({
                target: document.querySelector(".optsBox"),
            });
            selectOpts
                .getProjectListBystatus({ size: 9999, status: [1, 2] })
                .then((res) => {
                    loading.close();
                    this.projectOpt = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除费用明细
        del(index) {
            this.form.inputInvoices.splice(index, 1);
        },
        //部门的选择
        departmentClick(node) {
            this.form.deptId = node.id;
        },
        //部门清空
        clearDept() {
            this.form.deptId = "";
        },
        // 新增进项发票 打开弹框
        openpopCoseEditInputBill() {
            this.popCoseEditInputBillVisible = true;
        },
        // 新增进项发票 关闭弹框
        closepopCoseEditInputBill() {
            this.detailsRow = null;
            this.popCoseEditInputBillVisible = false;
        },
        // 新增进项发票 接收子组件数据
        saveBill(row) {
            if (this.detailsRow) {
                // 编辑
                for (let i = 0; i < this.form.inputInvoices.length; i++) {
                    const item = this.form.inputInvoices[i];
                    if (item.id === this.detailsRow.id) {
                        this.form.inputInvoices.splice(i, 1, row); //通过splice 替换数据 触发视图更新
                    }
                }
            } else {
                // 新增
                this.form.inputInvoices.push(row);
            }
        },
        // 新增进项发票 编辑
        editInputBill(row) {
            this.detailsRow = row || null;
            // 新增进项发票 打开弹框
            this.openpopCoseEditInputBill();
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {
        ...mapGetters(["applyType", "userInfo"]),
        dialogTitle() {
            if (this.id) {
                return `${this.justSee ? "查看" : "编辑"}费用`;
            } else {
                return "新增费用";
            }
        },
    },

    watch: {
        // 财务想更改报销人，部门也跟着联动
        "form.occurUserId": {
            deep: true,
            immediate: true,
            handler() {
                if (this.form.occurUserId) {
                    this.commonJs
                        .getPersonDept(this.form.occurUserId)
                        .then((res) => {
                            this.form.deptId = res.deptId;
                        });
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.baseInfo {
    font-size: 16px;
    padding: 20px 0 40px;
}
</style>