// 选择公司所有人
<template>
    <el-select v-model="personId"
               filterable
               placeholder="请选择"
                @change="selectChange"
               :multiple="multiple"
               :clearable="needClean">
        <el-option v-for="item in userList"
                   :key="item.code"
                   :label="item.label"
                   :value="item.code">
        </el-option>
    </el-select>
</template>
<script>
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popAddOrgan",
    components: {},
    props: {
        value: {
            type: String,
            default: "",
        },
        // 是否需要清空功能
        needClean: {
            type: Boolean,
            default: true,
        },
        // 多选
        multiple:{
            default: false
        }
    },
    data() {
        return {
            userList: [], //人员列表
            personId: "",
        };
    },
    mounted() {
        this.getAllUserList();
    },
    methods: {
        //获取人员列表
        getAllUserList() {
            commonAPI.getAllUserList().then((res) => {
                this.userList = res.content;
                this.personId = this.value;
            });
        },

        //人员选择 change事件
        selectChange(val){
            let choosePerson = this.userList.filter((v)=>{
                return v.code === val
            });
            this.$emit("choosePerson",choosePerson)
        }
    },
    computed: {},
    watch: {
        value(newValue) {
            this.personId = newValue;
        },
        personId() {
            this.$emit("input", this.personId);
        },
    },
};
</script>
<style scoped>
</style>