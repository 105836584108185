// 编辑进项发票
// popCoseEditInputBill
<template>

    <div class="popCoseEditInputBill">
        <el-dialog :title="id?'编辑进项发票':'新增进项发票'"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   custom-class="popCoseEditInputBillDialog"
                   :append-to-body="true"
                   width="1000px"
                   :before-close="close">
            <el-form :model="form"
                     ref="form"
                     :rules="formRules"
                     label-position="top"
                     :inline="false">

                <el-row :gutter="60">
                    <el-col :span="8">
                        <el-form-item label="发票类型"
                                      prop="type">
                            <el-select v-model="form.type"
                                       placeholder="请选择发票类型">
                                <el-option v-for="item in billTypeOpts"
                                           :key="item.code"
                                           :label="item.label"
                                           :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"
                            v-if="companyInput">
                        <el-form-item label="销方公司"
                                      prop="sellerCompanyName">
                            <el-input v-model="form.sellerCompanyName"
                                      placeholder="请输入销方公司"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8"
                            v-else>
                        <el-form-item label="销方公司"
                                      prop="sellerCompanyId">
                            <cust-sup-choose placeholderStr="请选择销方公司"
                                             :showSeeProvider="false"
                                             :disable="true"
                                             :shouldClean="true"
                                             :isScorll="true"
                                             :backShow="form.sellerCompanyId ? {value: form.sellerCompanyId} : null"
                                             @handClear="clearProvider"
                                             :cleanProvider="cleanProvider"
                                             @treeSelectNodeClick="customerClick"></cust-sup-choose>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="税号"
                                      prop="taxId">
                            <el-input v-model="form.taxId"
                                      placeholder="请输入税号"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="开票日期"
                                      prop="occurDate">
                            <el-date-picker v-model="form.occurDate"
                                            type="date"
                                            value-format="yyyy-MM-dd"
                                            format="yyyy年MM月dd日"
                                            placeholder="选择开票日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="发票号码"
                                      prop="invoiceNumber">
                            <el-input v-model="form.invoiceNumber"
                                      placeholder="请输入发票号码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="内容"
                                      prop="content">
                            <el-input v-model="form.content"
                                      placeholder="请输入内容"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <!-- 收票明细-表格 -->
                <div class="formTable">
                    <div class="label"><i class="red">*</i> 收票明细 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <el-radio-group v-model="form.taxIncluded"
                                        @change="calcAmount">
                            <el-radio :label="true">含税</el-radio>
                            <el-radio :label="false">不含税</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="tableBox">
                        <el-table :data="form.details"
                                  border
                                  ref="formTable"
                                  stripe>
                            <el-table-column prop="content"
                                             label="内容"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.content"
                                              placeholder="请输入内容"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="amount"
                                             label="金额（元）"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <el-input-money v-model="scope.row.amount"
                                                    placeholder="请输入金额"></el-input-money>
                                </template>
                            </el-table-column>
                            <el-table-column prop="taxRate"
                                             label="税率（%）"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.taxRate"
                                                     :max="99"
                                                     :min="0"
                                                     style="width: 100%"
                                                     :controls="false"
                                                     placeholder="请输入税率"></el-input-number>
                                </template>
                            </el-table-column>
                            <el-table-column prop="taxAmount"
                                             label="税额（元）"
                                             min-width="100">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.taxAmount"
                                              disabled
                                              placeholder="请输入税额"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column prop="id"
                                             label="操作"
                                             width="60">
                                <template slot-scope="scope">
                                    <el-popconfirm confirm-button-text="删除"
                                                   cancel-button-text="取消"
                                                   icon="el-icon-info"
                                                   icon-color="red"
                                                   @confirm="del(scope.$index)"
                                                   title="真的要删除吗？">
                                        <el-link type="danger"
                                                 slot="reference">删除</el-link>
                                    </el-popconfirm>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="tableAddBtnBar"
                             @click="addDetailsRow"><i class="el-icon-plus"></i>添加明细</div>
                    </div>
                </div>
                <div class="countBox">
                    <div class="countItem">
                        <div class="count">{{form.totalAmount || 0.00}}</div>
                        <div class="unit">价税合计（元）</div>
                        <div class="line"></div>
                    </div>
                </div>

                <el-row :gutter="60">
                    <el-col :span="24">
                        <el-form-item label="备注"
                                      prop="remark">
                            <el-input v-model="form.remark"
                                      type="textarea"
                                      rows="5"
                                      placeholder="请输入备注"
                                      clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <div class="popItemBox"
                     v-if="form.id">
                    <div class="title">附件</div>
                    <div class="p20">
                        <com-file-upload-list :showUpBtn="true"
                                              :uploadData="{resourceId: form.id, resourceType: 'addInvoiceInput'}"
                                              :neddLoadFile="true"
                                              :justShowFistOne="false"
                                              :isNeedPopTemDownload="false"
                                              :showDownloadBtn="true"
                                              :showDelBtn="true"></com-file-upload-list>
                    </div>
                </div>

                <div class="footer">
                    <el-button type="danger"
                               @click="close">取消</el-button>
                    <el-button type="primary"
                               @click="save()">确定</el-button>
                </div>
            </el-form>

        </el-dialog>

    </div>
</template>

<script>
import CustSupChoose from "../../../components/selectTree/custSupChoose";
import ElInputMoney from "../../../components/form/elInputMoney";
import ComFileUploadList from "../../../components/upload/comFileUploadList";
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
import commonAPI from "@/api/commonAPI.js";
export default {
    name: "popCoseEditInputBill",

    props: {
        // 编辑的内容
        details: {
            type: Object,
        },
        // 销方公司是否是输入框
        companyInput: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        CustSupChoose,
        ElInputMoney,
        ComFileUploadList,
    },

    data() {
        return {
            dialogVisible: true,
            form: {
                type: "", // 发票类型
                sellerCompanyId: "", // 销方公司
                taxId: "", // 税号
                occurDate: "", // 开票日期
                invoiceNumber: "", // 发票号码
                content: "", // 内容
                remark: "", // 备注
                taxIncluded: true, // 是否含税
                details: [{}],
            },
            formRules: {
                type: {
                    required: true,
                    message: "请验证发票类型",
                    trigger: "blur",
                }, // 发票类型
                occurDate: {
                    required: true,
                    message: "请验证开票日期",
                    trigger: "blur",
                }, // 开票日期
                invoiceNumber: {
                    required: true,
                    message: "请验证发票号码",
                    trigger: "blur",
                }, // 发票号码
            },
            // 发票类型
            billTypeOpts: this.commonJs.getSelectByType(
                "InvoiceType",
                "billTypeOpts",
                this,
                true
            ),
            detailsInfo: {}, // 详情信息
            editRowIndex: null, // 正在编辑的行
            popSelectTicketVisible: false, // 选择单据弹窗显示
        };
    },

    created() {},

    mounted() {
        if (this.details) {
            this.form = Object.assign({}, this.details);
        } else {
            // 获取新增的Id
            this.getId();
        }
    },

    methods: {
        // 保存进项发票
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let list = this.form.details.filter((item) => {
                        return !Number(item.amount);
                    });
                    if (list.length > 0 || this.form.details.length === 0) {
                        this.$message({
                            message: "请验证支出单据中的必填项！",
                            type: "error",
                        });
                        return;
                    }
                    let data = Object.assign({}, this.form, {
                        typeName: this.findTypeName(
                            this.billTypeOpts,
                            this.form.type
                        ),
                    });
                    this.$emit("saveBill", data);
                    this.close();
                }
            });
        },
        // 获取新增项目的Id
        getId() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            commonAPI
                .getId()
                .then((res) => {
                    loading.close();
                    this.form.id = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 添加明细
        addDetailsRow() {
            this.form.details.push({});
        },
        // 删除缴税费明细
        del(index) {
            this.form.details.splice(index, 1);
        },
        // 客户的选择
        customerClick(node) {
            this.form.sellerCompanyId = node.id;
        },
        // 客户的清空
        clearProvider() {
            this.form.sellerCompanyId = "";
        },
        // 查找收入类别中文label
        findTypeName(opts, code) {
            if (opts && code) {
                for (let i = 0; i < opts.length; i++) {
                    if (code === opts[i].code) {
                        return opts[i].label;
                    }
                    opts[i].hasOwnProperty("children")
                        ? this.findTypeName(opts[i].children, code)
                        : "";
                }
            }
        },
        // 计算价税合计
        calcAmount() {
            // 价税合计计算公式
            // 1、含税时：价税合计=金额
            // 2、不含税时：价税合计=金额+金额*税率
            let count = 0;
            this.form.details.forEach((item) => {
                if (this.form.taxIncluded) {
                    //  含税： 税额 = 税率 * 金额 /（1 + 税率）
                    count = this.commonJs.keepTwoDecimal(
                        count + Number(item.amount)
                    );
                    item.taxAmount =
                        this.commonJs.keepTwoDecimal(
                            (item.amount * (item.taxRate / 100)) /
                                (1 + item.taxRate / 100)
                        ) || 0;
                } else {
                    // 不含税： 税额 = 税率 * 金额
                    count = this.commonJs.keepTwoDecimal(
                        count +
                            Number(item.amount) +
                            Number(item.amount) * (item.taxRate / 100)
                    );
                    item.taxAmount =
                        this.commonJs.keepTwoDecimal(
                            item.amount * (item.taxRate / 100)
                        ) || 0;
                }
            });
            this.$set(this.form, "totalAmount", count);
            this.$set(this.form, "details", this.form.details);
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {
        ...mapGetters(["applyType"]),
    },

    watch: {
        // 收票明细
        "form.details": {
            deep: true,
            immediate: true,
            handler: function () {
                this.calcAmount();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.baseInfo {
    font-size: 16px;
    padding: 20px 0 40px;
}
</style>